import React from "react"
import tw from "twin.macro"
import {
  BaseMDXProvider,
  Container,
  H1,
  PageLayout,
  PrimaryContent,
  SecondaryContent,
  SectionHeader,
  Dek,
} from "../components"
import { dekSpacing } from "../components/css-utils"
import { useGetIntraPageNavIsVisible } from "./utils"

const AboutPageTemplate = ({ children, pageContext }) => {
  const { frontmatter } = pageContext
  const contentContainerRef = React.useRef()

  const [referenceElementRef, navIsVisible] = 
    useGetIntraPageNavIsVisible(refElement => refElement.offsetTop - 40) // prettier-ignore

  return (
    <PageLayout
      navIsVisible={navIsVisible}
      title="About us"
      mdxChildren={children}
      contentContainerRef={contentContainerRef}
      seoData={{ description: frontmatter.introduction }}
    >
      <Container ref={referenceElementRef} className="mt-6 lg:mt-10">
        <H1 className="col-span-12 text-center mb-10 capitalize text-av-gray-6">
          about us
        </H1>
        <PrimaryContent className="border-t border-solid border-black" />
        <SecondaryContent
          as={Dek}
          css={[dekSpacing]}
          className="text-center text-av-text-color"
        >
          {frontmatter.introduction}
        </SecondaryContent>
      </Container>
      <BaseMDXProvider ref={contentContainerRef} components={{ SectionHeader }}>
        {children}
      </BaseMDXProvider>
    </PageLayout>
  )
}

export default AboutPageTemplate
