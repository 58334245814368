import React from "react"
import tw from "twin.macro"
import ReactMD from "react-markdown"

import {
  Container,
  H1,
  Dek,
  PrimaryContent,
  SecondaryContent,
  Body1,
  MainNav,
  Footer,
  Seo,
} from "../components"
import { dekSpacing } from "../components/css-utils"

function GlossaryDefinition({ definition }) {
  return (
    <>
      <Body1
        css={{ scrollMarginTop: "120px" }} // height of the main nav
        id={encodeURIComponent(definition.title)}
        tw="font-bold font-sans"
      >
        {definition.title}
      </Body1>
      <Body1
        as={ReactMD}
        source={definition.definition}
        css={{
          "& p": tw`mb-6 text-av-text-color`,
          "& a": tw`text-av-purple hover:underline`,
        }}
      />
    </>
  )
}

const GlossaryPageTemplate = ({ pageContext: { frontmatter }, location }) => {
  return (
    <>
      <Seo title="Glossary" description={frontmatter.introduction} />
      <MainNav className="sticky top-0 z-10 bg-white mb-6 lg:mb-10" />
      <Container>
        <H1 className="col-span-12 text-center mb-6 lg:mb-10 capitalize">
          glossary
        </H1>
        <PrimaryContent className="border-t border-solid border-black" />
        <SecondaryContent
          as={Dek}
          css={[dekSpacing]}
          className="text-center text-av-text-color"
        >
          {frontmatter.introduction}
        </SecondaryContent>
        <PrimaryContent className="mb-6 sm:mb-10 border-t border-solid border-black" />
      </Container>
      <Container className="lg:mb-14 md:mb-16 mb-10">
        <PrimaryContent>
          {frontmatter.definitions.map((definition, index) => {
            return <GlossaryDefinition definition={definition} key={index} />
          })}
        </PrimaryContent>
      </Container>
      <Footer />
    </>
  )
}

export default GlossaryPageTemplate
