import React from "react"

import AboutPageTemplate from "./about-page"
import GlossaryPageTemplate from "./glossary"

const templatesMap = {
  glossary: GlossaryPageTemplate,
  "about-page": AboutPageTemplate,
  undefined: () => <div>Not Found</div>,
}

const PageTemplateInit = props => {
  const {
    pageContext: { frontmatter: { templateKey } = {} },
  } = props
  const PageComponent = templatesMap[templateKey] ?? (() => null)
  return <PageComponent {...props} />
}

export default PageTemplateInit
