import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/templates/default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const SectionHeader = makeShortcode("SectionHeader");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <SectionHeader subtitle="" title="The Approach" mdxType="SectionHeader" />
    <p>{`In the current information ecosystem – propelled by immediacy, distortion and speculation – our collective ability to think clearly about the long-term consequences of present-day actions has been impaired. For this reason, our work will be defined as much by process as by subject matter according to the following guidelines:`}</p>
    <p><strong parentName="p">{`We will approach issues with curiosity and an open mind,`}</strong>{` pursuing topics we believe are not sufficiently understood.`}</p>
    <p><strong parentName="p">{`We will embrace complexity.`}</strong>{` At a time when information is being delivered in ever-smaller and simplified parcels, we will explore questions with depth and detail.`}</p>
    <p><strong parentName="p">{`We will seek to connect the abstract to the concrete`}</strong>{` in order to illuminate connections between large-scale shifts in policy or technology and individual behaviors and vice-versa. `}</p>
    <p><strong parentName="p">{`We will work with respected voices and authorities in the areas we study,`}</strong>{` supporting and collaborating with journalists, academics and practitioners.`}</p>
    <SectionHeader subtitle="" title="Support" mdxType="SectionHeader" />
    <p>{`Aventine is supported by Select Equity Group, L.P. an asset management firm founded in 1990 committed to rigorous, independent and fact-driven research.  `}</p>
    <SectionHeader subtitle="" title="The Name" mdxType="SectionHeader" />
    <p>{`Aventine is named after the outermost of the seven hills of Rome, where the view of what lies outside the immediate city is most unobstructed. In the spirit of looking beyond what is immediately in front of us, Aventine will tackle subjects and questions that we believe have critical bearing on the future, but which are not well understood.`}</p>
    <SectionHeader subtitle="" title="Staff and Contributors" mdxType="SectionHeader" />
    <p><strong parentName="p">{`DAVID AUTOR`}</strong>{`  (`}<a parentName="p" {...{
        "href": "/podcast"
      }}>{`episode two of `}<em parentName="a">{`The World as You’ll Know It`}</em></a>{`) is the Ford Professor of Economics at MIT and the co-chair of MIT’s `}<a parentName="p" {...{
        "href": "https://workofthefuture.mit.edu/"
      }}>{`Work of the Future`}</a>{` task force. He has written extensively about the dynamics of the labor market and three papers in particular bear upon his conversation with Steven Greenhouse about the effect of Covid-19 on workers: `}<em parentName="p"><a parentName="em" {...{
          "href": "https://www.hamiltonproject.org/assets/files/AutorReynolds_LO_FINAL.pdf"
        }}>{`The Nature of Work After the Covid Crisis: Too Few Low-Wage Jobs`}</a>{`; `}<a parentName="em" {...{
          "href": "https://www.nber.org/papers/w25588.pdf"
        }}>{`Work of the Past, Work of the Future`}</a></em>{` and `}<em parentName="p"><a parentName="em" {...{
          "href": "https://www.nber.org/papers/w21906.pdf"
        }}>{`The China Shock: Learning From Labor Market Adjustment to Large Changes in Trade`}</a></em>{`.  `}</p>
    <p><strong parentName="p">{`JARED BERNSTEIN`}</strong>{` (episode three of `}<em parentName="p"><a parentName="em" {...{
          "href": "/podcast"
        }}>{`The World as You’ll Know It`}</a></em>{`) is a member of the White House Council of Economic Advisers for the Biden administration. From 2009 to 2011 he was the chief economist and economic adviser to Vice President Joe Biden, executive director of the White House Task Force on the Middle Class and a member of President Obama’s economic team. Prior to joining the Obama administration, Bernstein was a senior economist and the director of the Living Standards Program at the Economic Policy Institute in Washington, D.C. Between 1995 and 1996 he held the post of deputy chief economist at the U.S. Department of Labor. He is the author of multiple books, the most recent of which is `}<em parentName="p"><a parentName="em" {...{
          "href": "https://www.amazon.com/Reconnection-Agenda-Reuniting-Growth-Prosperity/dp/1511769386/ref=tmm_pap_swatch_0?_encoding=UTF8&qid=&sr="
        }}>{`The Reconnection Agenda: Reuniting Growth and Prosperity`}</a></em>{`.`}</p>
    <p><strong parentName="p">{`JULIÁN CASTRO`}</strong>{` (episode five of `}<em parentName="p"><a parentName="em" {...{
          "href": "/podcast"
        }}>{`The World as You’ll Know It`}</a></em>{`) was the mayor of San Antonio, Texas, from 2009 to 2014. He also served as secretary of Housing and Urban Development from 2014 to 2017.`}</p>
    <p><strong parentName="p">{`JAMIE CONDLIFFE,`}</strong>{` Aventine'sNewsletter Editor, is an editor and writer covering business and technology. He was most recently executive editor at Protocol, a sibling publication to POLITICO that focused on the tech industry. Prior to joining Protocol, he was an editor on the business desk at The New York Times, a news editor at MIT Technology Review and a writer at Gizmodo. He has held lectureships at the University of Oxford and Imperial College London and holds a doctorate in engineering from the University of Oxford.`}</p>
    <p><strong parentName="p">{`CHRISTIANA FIGUERES`}</strong>{` (episode one of `}<em parentName="p"><a parentName="em" {...{
          "href": "/podcast"
        }}>{`The World as You’ll Know It`}</a></em>{`) is the former secretary of the United Nations Framework Convention on Climate Change and played a vital role in the negotiations that led to the landmark Paris Agreement of 2015. She is also the co-author of `}<em parentName="p"><a parentName="em" {...{
          "href": "https://globaloptimism.com/the-future-we-choose/"
        }}>{`The Future We Choose; Surviving the Climate Crisis`}</a></em>{` and the co-host of the `}<em parentName="p"><a parentName="em" {...{
          "href": "https://outrageandoptimism.libsyn.com/"
        }}>{`Outrage + Optimism`}</a></em>{` podcast.`}</p>
    <p><strong parentName="p">{`LYNDON FRENCH`}</strong>{` (photographs for "`}<a parentName="p" {...{
        "href": "/artificial-intelligence/feature/john-deere"
      }}>{`One Company’s Decision to Put AI Into the Ground`}</a>{`") is an editorial and commercial photographer based in Chicago, Illinois. You can find out more about him and his work at `}<a parentName="p" {...{
        "href": "https://lyndonfrench.com/Information"
      }}>{`lyndonfrench.com`}</a>{`.`}</p>
    <p><strong parentName="p">{`STEVEN GREENHOUSE`}</strong>{` (episodes two and three of `}<em parentName="p"><a parentName="em" {...{
          "href": "/podcast"
        }}>{`The World as You’ll Know It`}</a></em>{`) was a reporter for The New York Times for over 30 years, covering labor and the workplace for many of them. He is the author of two books: `}<em parentName="p"><a parentName="em" {...{
          "href": "https://www.penguinrandomhouse.com/books/246798/beaten-down-worked-up-by-steven-greenhouse/"
        }}>{`Beaten Down, Worked Up: The Past, Present, and Future of American Labor`}</a></em>{` and `}<em parentName="p"><a parentName="em" {...{
          "href": "https://www.penguinrandomhouse.com/books/71431/the-big-squeeze-by-steven-greenhouse/"
        }}>{`The Big Squeeze: Tough Times for the American Worker`}</a></em>{`.`}</p>
    <p><strong parentName="p">{`KONSTANTIN KAKAES`}</strong>{` ("`}<a parentName="p" {...{
        "href": "/artificial-intelligence/history-of-ai"
      }}>{`The Five Epochs of AI`}</a>{`") is an editor at `}<em parentName="p"><a parentName="em" {...{
          "href": "https://www.technologyreview.com/"
        }}>{`MIT Technology Review`}</a></em>{`.`}</p>
    <p><strong parentName="p">{`MICHAEL KIMMELMAN`}</strong>{` (episode five of `}<em parentName="p"><a parentName="em" {...{
          "href": "/podcast"
        }}>{`The World as You’ll Know It`}</a></em>{`) is the architecture critic of `}<em parentName="p">{`The New York Times`}</em>{` and the founder and editor at large of Headway, a journalistic initiative at `}<em parentName="p">{`The New York Times`}</em>{` focused on global challenges and paths toward progress that will debut in 2021. `}</p>
    <p><strong parentName="p">{`PAUL LEBLANC`}</strong>{` (episode four of `}<em parentName="p"><a parentName="em" {...{
          "href": "/podcast"
        }}>{`The World as You’ll Know It`}</a></em>{`) has been the president of Southern New Hampshire University since 2003. He was the president of Marlboro College from 1996 to 2003. In 2015 he served as senior policy adviser to Under Secretary Ted Mitchell at the U.S. Department of Education. He is also the chair of the Board of Directors of the American Council on Education.`}</p>
    <p><strong parentName="p">{`GIDEON LEWIS-KRAUS`}</strong>{` (“`}<a parentName="p" {...{
        "href": "/artificial-intelligence/feature/john-deere"
      }}>{`One Company’s Decision to Put AI Into the Ground`}</a>{`”) is a staff writer for `}<em parentName="p">{`The New Yorker`}</em>{` magazine. He has also written for `}<em parentName="p">{`The New York Times Magazine`}</em>{` and `}<em parentName="p">{`Wired`}</em>{`. He is the author of `}<em parentName="p"><a parentName="em" {...{
          "href": "https://www.amazon.com/Sense-Direction-Pilgrimage-Restless-Hopeful/dp/1594487251"
        }}>{`A Sense of Direction: Pilgrimage for the Restless and the Hopeful`}</a></em>{`. You can find out more about him at `}<a parentName="p" {...{
        "href": "http://www.gideonlk.com/"
      }}>{`gideonlk.com`}</a>{`.`}</p>
    <p><strong parentName="p">{`DANIELLE MATTOON`}</strong>{` is the executive director of Aventine. She comes to Aventine after 15 years working at `}<em parentName="p">{`The New York Times`}</em>{`, where she was most recently culture editor. Prior to `}<em parentName="p">{`The New York Times`}</em>{`, she worked at `}<em parentName="p">{`National Public Radio`}</em>{`, `}<em parentName="p">{`The New Yorker`}</em>{`, `}<em parentName="p">{`Rolling Stone`}</em>{` and `}<em parentName="p">{`Talk`}</em>{` magazine.`}</p>
    <p><strong parentName="p">{`ARIANA McLAUGHLIN`}</strong>{` (photography and photo editing throughout site) is a freelance photographer and photo editor. From 2013 to 2018 she was a photo editor at `}<em parentName="p">{`The New York Times`}</em>{`. You can find out more about her at `}<a parentName="p" {...{
        "href": "http://www.arianamclaughlin.com/"
      }}>{`arianamclaughlin.com`}</a>{`. `}</p>
    <p><strong parentName="p">{`MAE RYAN`}</strong>{` (video for "`}<a parentName="p" {...{
        "href": "/robotics/feature"
      }}>{`The Next Generation of Robots is Here`}</a>{`") is an Emmy-award winning director and producer. She has worked as a senior video producer at `}<em parentName="p">{`The New York Times`}</em>{` and `}<em parentName="p">{`The Guardian`}</em>{` and is currently producing documentary series for Showtime, HBO & FX.`}</p>
    <p><strong parentName="p">{`JANETTE SADIK-KHAN`}</strong>{` (episode five of `}<em parentName="p"><a parentName="em" {...{
          "href": "/podcast"
        }}>{`The World as You’ll Know It`}</a></em>{`) was commissioner of the New York City Department of Transportation from 2007–2013 under Mayor Michael Bloomberg; she is now a principal at Bloomberg Associates. She is the author of `}<em parentName="p"><a parentName="em" {...{
          "href": "https://www.indiebound.org/book/9780143128977"
        }}>{`Streetfight: Handbook for an Urban Revolution`}</a></em>{`.`}</p>
    <p><strong parentName="p">{`CLIVE THOMPSON`}</strong>{` (“`}<a parentName="p" {...{
        "href": "/robotics/feature"
      }}>{`The Next Generation of Robots is Here`}</a>{`”; “`}<a parentName="p" {...{
        "href": "/robotics/final-frontier"
      }}>{`Robots Final Frontier`}</a>{`”; “`}<a parentName="p" {...{
        "href": "/robotics/history-of-robotics"
      }}>{`13 Milestones in the History of Robotics`}</a>{`”) is a contributor to `}<em parentName="p">{`The New York Times Magazine`}</em>{` and `}<em parentName="p">{`Wired`}</em>{` and writes a column about the history of technology for `}<em parentName="p">{`Smithsonian Magazine`}</em>{`. He is the author of `}<em parentName="p"><a parentName="em" {...{
          "href": "https://www.amazon.com/Coders-Making-Tribe-Remaking-World/dp/0735220565"
        }}>{`Coders: The Making of a New Tribe and the Remaking of the World`}</a></em>{` and `}<em parentName="p"><a parentName="em" {...{
          "href": "https://www.amazon.com/Smarter-Than-You-Think-Technology/dp/1594204454"
        }}>{`Smarter Than You Think: How Technology Is Changing Our Minds for the Better`}</a></em>{`. You can find out more about him at `}<a parentName="p" {...{
        "href": "https://www.clivethompson.net/"
      }}>{`clivethompson.net`}</a>{`. `}</p>
    <p><strong parentName="p">{`PAUL TOUGH`}</strong>{` (episode four of `}<em parentName="p"><a parentName="em" {...{
          "href": "/podcast"
        }}>{`The World as You’ll Know It`}</a></em>{`) is the author of `}<em parentName="p"><a parentName="em" {...{
          "href": "https://www.paultough.com/books/years-that-matter-most/"
        }}>{`The Years That Matter Most: How College Makes or Breaks Us`}</a></em>{` and `}<a parentName="p" {...{
        "href": "https://www.paultough.com/the-books/how-children-succeed/"
      }}><em parentName="a">{`How Children Succeed: Grit, Curiosity, and the Hidden Power of Character`}</em>{`. He is`}</a>{` a contributing writer to `}<a parentName="p" {...{
        "href": "https://www.paultough.com/articles/"
      }}><em parentName="a">{`The New York Times Magazine`}</em>{`;`}</a>{` his writing has also appeared in `}<em parentName="p"><a parentName="em" {...{
          "href": "https://www.newyorker.com/magazine/2011/03/21/the-poverty-clinic"
        }}>{`The New Yorker`}</a></em>{`, `}<em parentName="p"><a parentName="em" {...{
          "href": "https://www.theatlantic.com/author/paul-tough/"
        }}>{`The Atlantic`}</a></em>{`, `}<em parentName="p"><a parentName="em" {...{
          "href": "http://www.gq.com/entertainment/music/200909/gregg-gillis-girl-talk-legal-mash-up"
        }}>{`GQ`}</a></em>{`, `}<em parentName="p"><a parentName="em" {...{
          "href": "http://textfiles.com/news/terminal.esq"
        }}>{`Esquire`}</a></em>{` and on the `}<em parentName="p"><a parentName="em" {...{
          "href": "http://www.nytimes.com/2010/08/20/opinion/20tough.html?pagewanted=all"
        }}>{`Op-Ed page`}</a></em>{` of The New York Times.`}</p>
    <p><strong parentName="p">{`DAVID WALLACE-WELLS`}</strong>{` (episode one of `}<em parentName="p"><a parentName="em" {...{
          "href": "/podcast"
        }}>{`The World as You’ll Know It`}</a></em>{`) is the author of `}<em parentName="p"><a parentName="em" {...{
          "href": "https://www.penguinrandomhouse.com/books/586541/the-uninhabitable-earth-by-david-wallace-wells/"
        }}>{`The Uninhabitable Earth: Life After Warming`}</a></em>{`, a best-selling book based on a story he wrote in 2017, which was the most widely read in the history of New York magazine. He writes about climate and other issues for New York magazine.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      